<template>
  <div class="input-check-group">
    <h2 class="input-check-group__title">
      {{ input.title }}
    </h2>
    <truncated-list :initial-limit="10" :items="input.checks">
      <template v-slot="check: Check">
        <input-check
          :is-disabled="isDisabled(check)"
          :is-loading="check.isLoading"
          :label="check.label"
          :state="check.state"
          :update="(s) => check.onChange(s === 'checked')"
          :number="check.number"
          theme="black-90"
          class="input-check-group__check"
        />
      </template>
    </truncated-list>
  </div>
</template>

<script setup lang="ts">
import TruncatedList from "@/components/content/truncated-list.vue";
import InputCheck from "@/components/inputs/input-check.vue";

export type Check = Pick<InstanceType<typeof InputCheck>, "isLoading" | "state" | "label" | "number"> & {
  inputType: "InputCheck";
  onChange: (v: boolean) => void | Promise<void>;
  disableWhen?: (input: Check) => boolean;
};

export type CheckGroup = {
  inputType: "InputCheckGroup";
  checks: Check[];
  title: string;
};

defineProps<{ input: CheckGroup }>();

const isDisabled = (check: Check) => (typeof check.disableWhen === "function" ? check.disableWhen(check) : false);
</script>

<style scoped lang="scss">
.input-check-group {
  width: 100%;
  flex-shrink: 0;

  &__check {
    align-items: center;
  }

  &__title {
    font-weight: 500;
    padding: 7px 10px;
    background: var(--menu-bg);
    width: 100%;
    min-height: var(--app-button-height-medium);
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-top: 1px solid var(--black-10);
  }
}
</style>
